import React from 'react';
import * as AppActions from '../../AppActions';
import ProductGrid from '../../Templates/ProductList/ProductGrid';
import BrowseHeader from '../../Components/BrowseHeader';
import {getOutlet} from 'reconnect.js';

export function getExtraProductListProps(props) {
  let topFilter = hasCustomTopFilter(props.location.pathname);
  let filterMenuType = getCustomFilterMenuType(props.location.pathname);

  let onCustomClick = async (item) => {
    if (props.location.pathname.indexOf('/products') > -1) {
      await AppActions.navigate(`/product?id=${item.id}`, {loading: true});
    } else if (props.location.pathname.indexOf('/articles') > -1) {
      await AppActions.navigate(`/article?id=${item.id}`, {loading: true});
    }
  };

  function renderCustomSection({route, sectionId, params}) {
    if (sectionId === 'A') {
      return <BrowseHeader route={route} cat={params.cat} />;
    }
    return null;
  }

  return {
    ...props,
    ProductGrid,
    topFilter,
    filterMenuType,
    onCustomClick,
    renderCustomSection,
  };
}

export function getExtraProductDetailPage(props) {
  let filterMenuType = getCustomFilterMenuType(props.location.pathname);

  function renderCustomSection(sectionId, params = {}) {
    const {lang} = getOutlet('locale').getValue();

    const {product} = params;
    if (sectionId === 'D' && product?.type === 'room') {
      const {
        original_price,
        price,
        friday_price,
        saturday_price,
        sunday_price,
        early_discount,
        early_days,
        price_overrides,
      } = product;

      return lang === 'tw' ? (
        <div
          style={{
            borderLeft: 'solid 2px var(--secondColor)',
            paddingLeft: 8,
            marginBottom: 12,
          }}>
          <div>原價 NT$ {original_price}</div>
          <div>平日價格 NT$ {price}</div>
          <div>週五價格 NT$ {friday_price}</div>
          <div>週六價格 NT$ {saturday_price}</div>
          <div>週日價格 NT$ {sunday_price}</div>
          <div>早鳥價再享 {Math.round((1 - early_discount) * 100)}% 優惠</div>
          {Array.isArray(price_overrides) &&
            price_overrides.map((item, idx) => (
              <div key={idx}>
                特殊節日 {item.name} NT$ {item.price} ({item.start_date} ~{' '}
                {item.end_date})
              </div>
            ))}
        </div>
      ) : (
        <div
          style={{
            borderLeft: 'solid 2px var(--secondColor)',
            paddingLeft: 8,
            marginBottom: 12,
          }}>
          <div>Original price NT$ {original_price}</div>
          <div>Price on weekdays NT$ {price}</div>
          <div>Firday price NT$ {friday_price}</div>
          <div>Saturday price NT$ {saturday_price}</div>
          <div>Sunday price NT$ {sunday_price}</div>
          <div>
            Early bird discount {Math.round((1 - early_discount) * 100)}%{' '}
          </div>
          {Array.isArray(price_overrides) &&
            price_overrides.map((item, idx) => (
              <div key={idx}>
                Holiday price {item.name} NT$ {item.price} ({item.start_date} ~{' '}
                {item.end_date})
              </div>
            ))}
        </div>
      );
    }

    return null;
  }

  return {
    filterMenuType,
    renderCustomSection,
  };
}

export function getCustomFilterMenuType(pathname) {
  if (pathname.indexOf('/product') > -1) {
    return 'L2';
  } else if (pathname.indexOf('/article') > -1) {
    return 'L1';
  }
  return null;
}

export function hasCustomTopFilter(pathname) {
  if (pathname.indexOf('/article') > -1) {
    return true;
  }
  return false;
}
