import React from 'react';
import styled from 'styled-components';
import Calendar from 'rev.sdk.js/Components/Calendar';
import {DT} from 'rev.sdk.js/Components/Calendar/CalendarUtil';

function DateCell(props) {
  const {year, month, date, products, classNames: _classNames, onClick} = props;
  const curr = DT({year, month, date});

  let availSessions = [];
  let classNames = [];

  for (const product of products) {
    const [y, m, d] = product.session.date.split('-');
    if (
      curr.getFullYear() === parseInt(y) &&
      curr.getMonth() + 1 === parseInt(m) &&
      curr.getDate() === parseInt(d)
    ) {
      availSessions.push(product);
    }
  }

  if (availSessions.length === 0) {
    classNames.push('disabled');
  }

  return (
    <DateCellWrapper
      className={_classNames.concat(classNames).join(' ')}
      onClick={(e) => {
        onClick(e);
      }}>
      {date}
      {availSessions.length > 0 && (
        <div style={{fontSize: 4}}>{availSessions.length}場</div>
      )}
    </DateCellWrapper>
  );
}

const DateCellWrapper = styled.div`
  position: relative;

  /* here to custom*/
  &.disabled::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &.r-cell.r-date.r-today {
    border: solid 1px var(--primaryColor);
  }

  &.r-today::after {
    content: '';
  }

  &.r-cell.r-date.r-today.r-selected {
    font-wieght: 600;
    background-color: #ffffff;
  }

  &.r-cell.r-date.r-selected {
    background-color: var(--primaryColor);
    border: 1px solid var(--primaryColor);
  }

  &.r-cell.r-date:not(.disabled, .past):hover {
    border: none;
    background-color: var(--primaryColor);
  }
`;

function ProductDetailCalendar(props) {
  const {size, products, selectedDt, setSelectedDt} = props;

  return (
    <Wrapper size={size}>
      <div className="calendar">
        <Calendar
          onSelect={({year, month, date, dt}) => {
            setSelectedDt(
              selectedDt
                ? selectedDt.getTime() === dt.getTime()
                  ? null
                  : dt
                : dt,
            );
          }}
          renderDate={(props) => {
            return <DateCell key={props.date} {...props} products={products} />;
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 20px;
  width: ${(props) => props.size}px;

  & > .calendar {
    border: 1px solid #d9d9d9;

    & .r-inner > .r-row.r-head {
      & > .r-cell.r-title {
        font-weight: 500;
      }

      & > .r-cell.r-next:hover,
      .r-cell.r-prev:hover {
        background-color: var(--primaryColor);
      }
    }
  }
`;

export default ProductDetailCalendar;
